<template>
  <div v-if="list.pages[listInput.page]">
    <div
      v-if="list.pages[listInput.page].isLoading"
      class="globe-activity md-layout-item md-size-100 text-center"
    >
      <spinner :diameter="30"></spinner>
    </div>
    <md-table
      v-else
      :value="
        list.pages[listInput.page].items ? list.pages[listInput.page].items : []
      "
      class="globe-activity md-table-global-sales provider responsive"
    >
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Name" @click.native="handleSelect(item)"
          ><label style="color: blue; font-size: 16px; font-weight: bold">{{
            item.name
          }}</label
          ><br /><label style="color: green">{{
            item.practice
          }}</label></md-table-cell
        >
        <md-table-cell md-label="Phone" @click.native="handleSelect(item)">{{
          item.cellNumber
        }}</md-table-cell>
        <md-table-cell md-label="City" @click.native="handleSelect(item)">{{
          item.city
        }}</md-table-cell>
        <md-table-cell md-label="State" @click.native="handleSelect(item)">{{
          item.state
        }}</md-table-cell>
        <md-table-cell md-label="ZipCode" @click.native="handleSelect(item)">{{
          item.zipcode
        }}</md-table-cell>
        <md-table-cell md-label="Country" @click.native="handleSelect(item)">{{
          item.country
        }}</md-table-cell>
        <md-table-cell md-label="Address" @click.native="handleSelect(item)"
          ><label>{{ item.address1 + " " + item.address2 }}</label
          ><br /><label><a :href="item.webSite" /></label
        ></md-table-cell>
        <md-table-cell
          md-label="Specialization"
          @click.native="handleSelect(item)"
          ><label>{{ item.specialization }}</label
          ><br /><label>{{ item.subspecialization }}</label></md-table-cell
        >
      </md-table-row>
    </md-table>
    <md-dialog :md-active.sync="showDialog">
      <div class="modal-size-medium">
        <div class="modal-header">{{ details.name }}</div>
        <a class="cancel-profile" @click="cancel()">X</a>
        <div class="d-flex">
          <div class="modal-content scroll p-0 w-100">
            <div class="md-layout">
              <p class="md-layout-item md-size-50">
                <b>City:</b>
              </p>
              <p class="md-layout-item md-size-50">{{ details.city }}</p>
            </div>
            <div class="md-layout">
              <p class="md-layout-item md-size-50"><b>State:</b></p>
              <p class="md-layout-item md-size-50">{{ details.state }}</p>
            </div>
            <div class="md-layout">
              <p class="md-layout-item md-size-50">
                <b>Zip code:</b>
              </p>
              <p class="md-layout-item md-size-50">{{ details.zipcode }}</p>
            </div>
            <div class="md-layout">
              <p class="md-layout-item md-size-50">
                <b>Specialization:</b>
              </p>
              <p class="md-layout-item md-size-50">
                &nbsp;{{ details.specialization }}
              </p>
            </div>
            <div class="md-layout">
              <p class="md-layout-item md-size-50"><b>Phone:</b></p>
              <p class="md-layout-item md-size-50">{{ details.cellNumber }}</p>
            </div>
          </div>
          <div
            class="md-layout w-100"
            style="justify-content: space-around; margin-top: 5%"
          >
            <file-view-handler
              :options="{
                feature: 'User',
                featureId: this.details.userID,
                category: 'PROFILE',
              }"
              v-slot="{ uploadedFiles }"
            >
              <img
                v-if="uploadedFiles[0] != null"
                :src="uploadedFiles[0].link"
                alt="avatar"
                class="search-user-img"
              />
              <img
                v-else
                src="@/assets/img/user_avatar.png"
                alt="avatar"
                class="search-user-img"
              />
            </file-view-handler>
          </div>
        </div>
      </div>
    </md-dialog>
  </div>
</template>
<script>
import FileViewHandler from "../../../handlers/common-handlers/FileViewHandler";
export default {
  name: "ProviderListTable",
  components: {
    FileViewHandler,
  },
  inject: ["list", "listInput"],
  methods: {
    handleSelect(item) {
      this.showDialog = true;
      this.details = item;
      console.log(item);
    },
    cancel() {
      this.showDialog = false;
    },
    getImageFromFiles(uploadFiles, uploadedFiles) {
      if (uploadFiles && uploadFiles.length > 0) {
        return uploadFiles[0].link;
      } else if (uploadedFiles && uploadedFiles.length > 0) {
        return uploadedFiles[0].link;
      }
    },
  },
  data() {
    return {
      showDialog: false,
      details: {},
    };
  },
};
</script>

<style lang="scss" scoped>
.globe-activity {
  height: calc(100vh - 400px);
  overflow: auto;
}
.cancel-profile {
  position: absolute;
  right: 12px;
  top: 12px;
  border: 1px solid grey;
  padding: 3px 8px;
  color: black;
  border-radius: 5px;
}
.search-user-img {
  max-width: 50%;
  border-radius: 50%;
  border: 1px solid blue;
  height: fit-content;
}
.profile-dialog {
  padding: 15px;
}
.profile-val {
  font-weight: normal;
  text-transform: capitalize;
}
.md-list-item-content {
  font-weight: bolder !important;
}
.provider.responsive td:nth-of-type(1):before {
  content: "Name";
}
.provider.responsive td:nth-of-type(2):before {
  content: "Phone";
}
.provider.responsive td:nth-of-type(3):before {
  content: "City";
}
.provider.responsive td:nth-of-type(4):before {
  content: "state";
}
.provider.responsive td:nth-of-type(5):before {
  content: "Zipcode";
}
.provider.responsive td:nth-of-type(6):before {
  content: "Country";
}
.provider.responsive td:nth-of-type(7):before {
  content: "Address";
}
.provider.responsive td:nth-of-type(8):before {
  content: "Specialization";
}
</style>
