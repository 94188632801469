<template>
  <div>
    <list-layout>
      <template v-slot:content>
        <provider-list-table></provider-list-table>
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "../../../components/layouts/list-layout/ListLayout.vue";
import ProviderListTable from "./ProviderListTable.vue";
export default {
  components: { ListLayout, ProviderListTable },
};
</script>
